import { useContext } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { replaceThumborURL } from 'utils/url';
import { loaderStaticImage } from 'utils/image';
import { substringText, isUpperCase } from 'utils/string';
import { isBestRated } from 'utils/product';

import { MarketplaceContext } from 'components/core/Provider/MarketplaceProvider';
import DiscountBanner from '../../BlackNovember/DiscountBanner';
import Rating from './Rating';
import Icons from '../../Icons';
import ProductLink from '../../Link/Product';
import ProductImage from '../../Images/Product';
import { localStorageAddProductInformation } from '../../../utils/localStorage';
import { hasCouponBlackNovember } from '../../../utils/campaigns';

import '@hotmart/cosmos/dist/card/card.css';

const BestRated = loaderStaticImage({
  src: 's/static/images/icons/best_rated.svg'
});

const certificationIcon = loaderStaticImage({
  src: 's/static/images/icons/certification.svg'
});

const uuidv4 = require('uuid/v4');

const Price = dynamic(() => import('../../Price'), {
  ssr: false
});

const ProductTile = dynamic(() => import('../Tile'), {
  ssr: false
});

const Tag = dynamic(() => import('components/Tag'), {
  ssr: false
});

function ProductCard(props) {
  const {
    className,
    product = {},
    featured,
    onClick,
    showBuyButton,
    onBuyButtonClick,
    idPrefix,
    sectionName = null,
    isMobile,
    price,
    priceLoading,
    showPrice,
    campaignInfo,
    priority = false
  } = props;

  const {
    category,
    avatar,
    certification,
    description,
    finalAvatar,
    title,
    ownerName,
    slug,
    producerReferenceCode,
    rating,
    totalReviews,
    showRating,
    priceDiscount = '',
    fullLink,
    videoLink,
    isVideoCard,
    coupons
  } = product;

  const { t } = useTranslation('common');
  const seasonCampaign = campaignInfo;
  const marketplaceContext = useContext(MarketplaceContext);
  const { showNewProductCard, isBlackNovemberActive } = marketplaceContext;

  const categoryName = category && t(`category.${category}.name`);
  const avatarAvailable = finalAvatar || avatar;
  const staticMediaURL = replaceThumborURL(avatarAvailable);
  const totalString = showNewProductCard ? 45 : 60;
  const titleSubstring = title ? substringText(title, totalString) : '';
  const isCustomCampaign = seasonCampaign?.SLUG === 'um-por-cento';
  const sectionTitle = sectionName ? `- ${sectionName}` : '';
  const linkTitle = `${titleSubstring}: ${ownerName} ${sectionTitle}`;
  const isProductBestRated = isBestRated(rating);
  const hasBlackNovemberCoupon = hasCouponBlackNovember(coupons);

  const hasText =
    isCustomCampaign && seasonCampaign?.PRODUCT_CARD_TEXT
      ? seasonCampaign?.PRODUCT_CARD_TEXT
      : '';
  const isProductCard =
    (seasonCampaign?.PRODUCT_CARD_IMAGE && seasonCampaign?.PRODUCT_CARD_LOGO) ||
    isCustomCampaign;

  const productImageSize = showNewProductCard ? 350 : 313;

  const productClick = e => {
    if (isVideoCard && videoLink) {
      e.preventDefault();
    }
    localStorageAddProductInformation(product);
    onClick();
  };

  const renderRating = () => {
    return (
      <div
        className={classnames({
          'product-card--rating': showNewProductCard
        })}
      >
        <Rating
          showRating={showRating}
          rating={rating}
          totalReviews={totalReviews}
        />
        {showNewProductCard && renderStatus()}
      </div>
    );
  };

  const renderStatus = () => {
    if (!isMobile && showNewProductCard) {
      return (
        <>
          {isProductBestRated && (
            <Tag
              type="blue"
              icon={BestRated}
              label={t('general.best_rated')}
              size="custom-md"
              classes="tag__best-rated"
            />
          )}
          {!isProductBestRated && certification && (
            <Tag
              type="blue"
              icon={certificationIcon}
              label={t('general.certificate_of_completion')}
              size="custom-md"
            />
          )}
          {!isProductBestRated && !certification && (
            <div className="product-card__description">
              {substringText(description, 60)}
            </div>
          )}
        </>
      );
    }

    return null;
  };

  const renderTitle = () => {
    if (titleSubstring) {
      return (
        <div
          className={classnames('product-card__title ga-product-card-title', {
            'product-card__title--uppercase': isUpperCase(title)
          })}
        >
          {titleSubstring}
        </div>
      );
    }
    return false;

  };

  const renderAuthor = () => {
    if (ownerName) {
      return <div className="product-card__author">{ownerName}</div>;
    }
    return null;

  };

  const renderCardBody = () => {
    if (showNewProductCard) {
      return (
        <>
          <div className="product-card__info">
            {renderTitle()}
            {renderAuthor()}
          </div>
          {renderRating()}
        </>
      );
    }

    return (
      <>
        {renderRating()}
        {renderTitle()}
        {renderAuthor()}
      </>
    );
  };

  const renderCardFooter = () => {
    if (price) {
      return (
        <div className="product-card__prices">
          {isBlackNovemberActive && priceDiscount && (
            <div className="product-card__price--discount">
              <span>
                {`${t('season.filters.for')} `}
              </span>
              <Price isLoading={priceLoading} price={price} />
            </div>
          )}
          <div className="product-card__price">
            <Price isLoading={priceLoading} price={priceDiscount} />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <hot-card
      class={classnames('product-card', 'ga-product-card', className, {
        'product-card--new': showNewProductCard,
        'black-november-card': isBlackNovemberActive && hasBlackNovemberCoupon
      })}
      id={uuidv4()}
    >
      <ProductLink
        slug={slug}
        language={product.language}
        seasonSlug={seasonCampaign?.SLUG}
        producerReferenceCode={producerReferenceCode}
        onClick={() => productClick()}
        isSeasonProduct={seasonCampaign?.PREFIX}
        fullLink={fullLink}
        linkTitle={linkTitle}
        customSck={props.customSck}
      >
        <div>
          <ProductImage
            src={staticMediaURL}
            isSeasonActive={isProductCard}
            width={productImageSize}
            height={productImageSize}
            priority={priority}
            alt=""
            type="card-list"
          />
          {isProductCard && (
            <ProductTile
              src={`'${seasonCampaign?.PRODUCT_CARD_IMAGE}'`}
              className="product-card__tile"
              main={seasonCampaign?.PRODUCT_CARD_MAIN}
              isSeasonActive={isProductCard}
              text={hasText}
            />
          )}
        </div>
        <hot-card-body>
          {isBlackNovemberActive && hasBlackNovemberCoupon && (
            <DiscountBanner discountValue={coupons[0]?.discount} />
          )}
          <div onClick={() => isVideoCard && videoLink && onClick()}>
            {featured && (
              <div className="product-card__featured" style={{ width: '40px' }}>
                <Icons
                  prefix="fas"
                  iconName="star"
                  className="product-card__featured__star"
                />
              </div>
            )}
            <div
              className={classnames('product-card__content', {
                'product-card__content-season': isProductCard
              })}
            >
              {renderCardBody()}
              {renderCardFooter()}
            </div>
          </div>
        </hot-card-body>
      </ProductLink>
    </hot-card>
  );
}

ProductCard.propTypes = {
  className: PropTypes.string,
  product: PropTypes.object.isRequired,
  featured: PropTypes.bool,
  showBuyButton: PropTypes.bool,
  onBuyButtonClick: PropTypes.func,
  onClick: PropTypes.func,
  idPrefix: PropTypes.string,
  price: PropTypes.string,
  priceLoading: PropTypes.bool,
  showPrice: PropTypes.bool
};

ProductCard.defaultProps = {
  className: '',
  idPrefix: '',
  featured: false,
  showBuyButton: false,
  onBuyButtonClick: () => {},
  onClick: () => {},
  price: '',
  priceLoading: true,
  showPrice: false
};

export default ProductCard;
